<template>
  <div>
    <div class="title">
      <img src="@/assets/img/logo.png" alt="logo" class="logo-img" width="120px">
      <div style="font-weight: 700;">{{
        `现在是${mDateObj.year}年${mDateObj.month}月${mDateObj.day}日，${mDateObj.week}，${mDateObj.hour}:${mDateObj.min}`
      }}
      </div>
    </div>

    <div class="nav-out">
      <div class="left">
        <ul>
          <li v-if="userType === UserType.owner" @click="$emit('tab-click', 'showUserMain')">主页</li>
          <li v-if="userType === UserType.admin" @click="$emit('tab-click', 'showOwner')">主页</li>
          <li v-if="userType === UserType.owner" @click="$emit('tab-click', 'showDataCenter')">数据中心</li>
          <template v-if="userType === UserType.admin">
            <li @click="$emit('tab-click', 'showUserClass')">用户分析</li>
            <li>历史报警</li>
          </template>
        </ul>
      </div>
      <div class="right">
        <span @click="$emit('tab-click', 'showUserCenter')">个人中心</span>
        <span @click="logot">退出系统</span>
      </div>
    </div>
  </div>
</template>

<script>
import { UserType } from "@/utils/common";

export default {
  computed: {
    UserType() {
      return UserType
    }
  },
  data() {
    return {
      mDateObj: {
        year: 0,
        month: 0,
        day: 0,
        week: '',
        hour: 0,
        min: '',
      },
      userType: undefined,
      weekArray: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
    }
  },
  methods: {
    initTime() {
      let date = new Date()
      this.mDateObj.year = date.getFullYear()
      this.mDateObj.month = date.getMonth() + 1
      this.mDateObj.day = date.getDate()
      this.mDateObj.week = this.weekArray[date.getDay()]
      this.mDateObj.hour = date.getHours()
      this.mDateObj.min = (date.getMinutes() < 10) ? (`0${date.getMinutes()}`) : `${date.getMinutes()}`
    },
    logot() {
      this.$router.replace("/")
      localStorage.removeItem("token")
    }
  },
  mounted() {
    this.userType = localStorage.getItem("type");
  },
  created() {
    setInterval(this.initTime, 1000)
  }
}
</script>

<style lang="less" scoped>
.title {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  margin-top: 20px;
  box-sizing: border-box;
}

.nav-out {
  width: 100%;
  height: 45px;
  background-color: #FF9606;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    ul {
      list-style: none;
      display: flex;

      li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 100px;
        background: rgba(255, 255, 255, .3);
        color: #fff;
        padding: 2px;
        font-size: 14px;
        cursor: pointer;
      }

      li:nth-child(1) {
        margin-left: 60px;
      }

      li:nth-child(2) {
        margin-left: 20px;
      }

      li:nth-child(3) {
        margin-left: 20px;
      }

      li:nth-child(4) {
        margin-left: 20px;
      }

      li:hover {
        box-shadow: 0px 0px 2px #000;
      }
    }
  }

  .right {
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 100px;
      background: rgba(255, 255, 255, .3);
      color: #fff;
      padding: 2px;
      font-size: 14px;
      cursor: pointer;
    }

    span:nth-child(1) {
      margin-right: 30px;
    }

    span:nth-child(2) {
      margin-right: 60px;
    }

    span:hover {
      box-shadow: 0px 0px 2px #000;
    }
  }
}
</style>