<template>
    <div class="content">
        <div class="left">
            <h1>欢迎回来，御风</h1>
            <h1>当前在线设备：{{ onlineDeviceNum }}</h1>
            <h1>重点观察用户：12</h1>
            <h1>今日报警次数：<span style="color:red">0</span></h1>
            <h1>今日误报警次数：<span style="color:red">1</span></h1>
            <h1>异常用户（点击查询具体情况）</h1>
            <div>
                <span v-for="(item, index) in highFrequencyAbnormalList" :key="index"
                    @click="toUserDataCenter(highFrequencyUserIdList[index],'OnlineDevice')">{{ item }}</span>
            </div>
        </div>
        <div class="right">
            <PieChart ref="pieOne" :id="'pieChartOne'" :pieData="onLineDeviceVal" :unit="'%'" chartTitle="绑定设备在线率" />
            <PieChart :id="'pieChartTwo'" :pieData="ErrorVal" :unit="'次'" chartTitle="误报警率" />
        </div>
    </div>
</template>

<script>
import PieChart from "@/components/PieChart.vue"
import VueMixin from "@/utils/VueMixin";
export default {
    components: {
        PieChart
    },
    mixins: [VueMixin],
    data() {
        return {
            onLineDeviceVal: [
                {
                    name: "在线设备",
                    value: 80
                },
                {
                    name: "离线设备",
                    value: 20
                }
            ],
            ErrorVal: [
                {
                    name: "正确报警",
                    value: 80
                },
                {
                    name: "误报警",
                    value: 20
                }
            ],
            onlineDeviceNum: 0,
            highFrequencyAbnormalList: [],
            highFrequencyUserIdList: []
        }
    },
    methods: {
        async getOnlineBindDevicesRate() {
            let { data: res } = await this.$http.get(this.$api.getOnlineBindDevicesRate)
            this.onLineDeviceVal[0].value = parseInt(parseFloat(res.data.rate.toFixed(2)) * 100)
            this.onLineDeviceVal[1].value = 100 - parseInt(parseFloat(res.data.rate.toFixed(2)) * 100)
            this.$refs["pieOne"].drawPie()
        },

        async getOnlineDevices() {
            let { data: res } = await this.$http.get(this.$api.getOnlineDevices)
            this.onlineDeviceNum = res.data.number
            console.log(res)
        }
    },
    created() {
        this.getOnlineBindDevicesRate()
        this.getOnlineDevices()
        this.getHighFrequencyAbnormalUsers()
    }
}
</script>

<style lang="less" scoped>
.content {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

    .left {
        padding-left: 100px;

        h1:nth-child(1) {
            font-size: 28px;
            margin: 20px 0;
        }

        h1:not(:first-child) {
            font-size: 25px;
            margin: 10px 0;
        }

        h1:nth-child(6) {
            margin: 30px 0 20px;
        }

        div {
            display: inline-block;
            max-width: 400px;

            span {
                display: inline-block;
                width: auto;
                cursor: pointer;
                padding: 5px 10px 5px 0;
            }
        }
    }

    .right {
        margin-right: 200px;
    }
}
</style>