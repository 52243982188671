<template>
    <div class="home">
        <TabCom @tab-click="switchTab" v-if="isShow"></TabCom>
        <!-- <user-main v-if="showComponents[currentKey]===1"></user-main> -->
        <!-- <data-center v-else-if="showComponents[currentKey]===2"></data-center> -->
        <!-- <user-center v-else-if="showComponents[currentKey]===3"></user-center> -->
        <!-- <user-class v-else-if="showComponents[currentKey]===4"></user-class> -->
        <!-- <online-device v-else-if="showComponents[currentKey]===5"></online-device> -->
        <router-view />
    </div>
</template>

<script>
// @ is an alias to /src
import TabCom from '@/components/TabCom.vue'
import UserMain from "@/pages/UserMain.vue";
import DataCenter from "@/pages/DateCenter.vue";
import UserCenter from "@/components/UserCenter.vue";
import UserClass from "@/pages/UserClass.vue";
import OnlineDevice from "@/pages/OnlineDevice.vue";

export default {
    name: 'Home',
    components: {
        OnlineDevice,
        UserClass,
        UserCenter,
        DataCenter,
        UserMain,
        TabCom,
    },
    data() {
        return {
            currentKey: "showUserMain",
            showComponents: {
                "showUserMain": 1,
                "showDataCenter": 2,
                "showUserCenter": 3,
                "showUserClass": 4,
                "showOwner": 5,
            },
            userType: undefined,
            isShow: true
        }
    },
    mounted() {
        this.userType = localStorage.getItem("type")
        console.log(this.userType)
        if (this.userType === "owner") {
            this.currentKey = "showOwner"
        }

    },
    created() {
        this.$bus.$on('hideTab', this.hideTab)
    },
    methods: {
        switchTab(key) {
            console.log(key)
            switch (key) {
                case "showUserMain": {
                    this.$router.replace("/Home/UserMain")
                    break
                }
                case "showDataCenter": {
                    this.$router.replace("/Home/DataCenter")
                    break
                }
                case "showUserCenter": {
                    this.$router.replace("/Home/UserCenter")
                    break
                }
                case "showUserClass": {
                    this.$router.replace("/Home/UserClass")
                    break
                }
                case "showOwner": {
                    this.$router.replace("/Home/OnlineDevice")
                    break
                }
            }
        },
        hideTab() {
            this.isShow = false
            console.log("我被执行了", this.isShow)
        }
    },
    destroyed() {
        this.$bus.$off('hideTab', this.hideTab)
    }
}
</script>

<style lang="less" scoped>
.home {
    display: inline-block;
    width: 100%;
}
</style>
